<template>
  <div class="cdt">

<h2>Commercial Driver Training</h2>
<h3><i>Certificate of Completion</i></h3>
<hr>
<p>The economy depends on truck drivers to transport freight and keep supply chains moving. As the demand for goods increases, more truck drivers will be needed. Employment of heavy and tractor-trailer truck drivers is projected to grow 5 percent from 2014 to 2024, about as fast as the average of all occupations*. To meet the demand, companies are starting to invest in new trucks that are more fuel efficient and easier to drive. For example, some new heavy trucks are equipped with automatic transmissions, blind-spot monitoring, and variable cruise control.</p>

<p>The Professional Commercial Driver Training offered at Western Technical College is a 200-hour program, conducted in five weeks.</p>

<p>The Commercial Driver Training program is designed to prepare individuals with the knowledge base, procedures, and basic skills and abilities so that they will meet the Commercial Driver’s License Standards and industry job qualifications as a short and long-haul trainee driver.</p>

<p>Upon completion of the training program at Western Tech, graduates will be prepared to test for the Class A License.</p>

<p>*Source: The Labor Market &amp; Career Information Department (LMCI) of the Texas Workforce Commission <a href="http://www.lmci.state.tx.us/">www.lmci.state.tx.us</a></p>

<h2>About Western Technical College!</h2>
<hr>
<p>At Western Technical College we believe that education is an important step to finding a rewarding career in today’s fast paced world. That’s why our admissions department is trained to guide you through the career planning process.</p>

<p>Curriculum offerings are designed to help students prepare for career opportunities in various technical and healthcare fields.</p>

<h2>Features</h2>
<hr>

<h4>Two dedicated classrooms for instruction with:</h4>
<ul>
<li>Vehicle sub system component trainers</li>
<li>FMCSA Code 49 safety reference materials</li>
</ul>

  </div>
</template>

<script>



export default {
  name: 'cdt',
}
</script>
