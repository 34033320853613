<template>
  <div>
  <footer>
    <div class="container">
<p>For more information about our graduation rates, the median debt of students who completed the program, and other important information, please visit our consumer website at <a href="https://www.westerntech.edu/consumer-information" target="_blank">www.westerntech.edu/consumer-information</a></p>
<p>Individuals in photos are actual and not actual students, staff, or graduates of WTC.</p>
<p>&copy; Copyright - Western Technical College. - All rights reserved.</p>
<p><a href="https://www.westerntech.edu/privacy-policy-for-student-records/" target="_blank">Privacy Policy</a></p>
    </div>
  </footer>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
