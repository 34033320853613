<template>

	<div>

        <ValidationObserver ref="observer" v-slot="{ valid }">

                            <form action="" method="post" id="lead-form" @submit.prevent="onSubmit">

                              <div id="loader">
                                <i class="fa fa-spinner fa-spin"></i>
                              </div>
                                <div id="form_header">
                                    <h3 class="text-center">
                                        <a href="tel:+18554667780" id="tap_to_call">
                                        <span class="glyphicon glyphicon-earphone" aria-hidden="true"></span> (855) 466-7780                                        </a>
                                    </h3>

                                    <div class="arrow-down"></div>
                                </div>
                                <div id="form_body">
                                    <h3 class="text-center cta">Request Information Today!</h3>

                                    <ValidationProvider ref="provider" name="First Name" rules="required">
                                    <div class="form-group" slot-scope="{ errors, classes }" :class="classes">
                                        <label class="sr-only" for="first_name"></label>
                                        <input type="text" class="form-control" name="first_name" id="first_name" placeholder="First Name" v-model="first_name" >
                                        <p>{{ errors[0] }}</p>
                                    </div>
                                    </ValidationProvider>
                                    <ValidationProvider ref="provider" name="Last Name" rules="required">
                                    <div class="form-group" slot-scope="{ errors, classes }" :class="classes">
                                        <label class="sr-only" for="last_name"></label>
                                        <input type="text" class="form-control" name="last_name" id="last_name" placeholder="Last Name" v-model="last_name">
                                        <p>{{ errors[0] }}</p>
                                    </div>
                                    </ValidationProvider>

                                     <ValidationProvider ref="provider" name="Program" rules="required">
                                    <div class="form-group" slot-scope="{ errors, classes }" :class="classes">
                                        <label class="sr-only" for="program_of_interest"></label>
                                        <select class="form-control" id="program_of_interest" name="program_of_interest"  v-model="vprograms" @change="changeLink">
                                            <option v-for="option in programs" :key="option.value"  :disabled="option.disabled" :value="option.value" :selected="link == option.location ? true : false"  >{{option.text}}</option>
                                        </select>
                                        <p>{{ errors[0] }}</p>
                                    </div>
                                    </ValidationProvider>
                                    <ValidationProvider ref="provider" name="email" rules="required|email">
                                    <div class="form-group" slot-scope="{ errors, classes }" :class="classes">
                                        <label class="sr-only" for="email"></label>
                                        <input type="text" class="form-control" name="email" id="email" placeholder="Email" v-model="email">
                                         <p>{{ errors[0] }}</p>
                                    </div>
                                    </ValidationProvider>
                                     <ValidationProvider ref="provider" name="Phone" rules="required|phone">
                                    <div class="form-group"  slot-scope="{ errors, classes }" :class="classes">
                                        <label class="sr-only" for="phone"></label>
                                        <input type="tel" v-mask="['(###) ###-####']" class="form-control" name="phone" id="phone" placeholder="Phone"  v-model="phone">
                                        <p>{{ errors[0] }}</p>
                                    </div>
                                </ValidationProvider>
                                 <ValidationProvider ref="provider" name="Zip Code" rules="required|zip">
                                    <div class="form-group"  slot-scope="{ errors, classes }" :class="classes">
                                        <label class="sr-only" for="zip"></label>
                                        <input type="text"  v-mask="['#####']" class="form-control" name="zip" id="zip" placeholder="Zip Code"   v-model="zip" >
                                        <p>{{ errors[0] }}</p>
                                    </div>
                                </ValidationProvider>
                                    <div class="form-group">

  <button
  type ="submit"

    data-test="button"
     class="btn"
  >
    GET STARTED
  </button>

                                    </div>



                                    <div class="form-group">
                                        <p class="form-disclaimer">We Value Your Privacy<br>
We know you are anxious to get started on your new career. By pressing the button in the form on this page, I understand that Western Technical College may call and/or text me about educational services at the phone number provided, including a wireless number, using automated technology. Your information will only be used by Western Technical College. We do not and will not sell your information to any other party. Please note, that you are not required to provide this consent to attend our institutions.</p>
                                    </div>
                                </div>

                            </form>
                        </ValidationObserver>
	</div>
</template>

<script>
    import axios from 'axios'
    import VueAxios from 'vue-axios'

    export default {
        name: 'Form',
        data: () => ({
          selected: '',
            vprograms:'',
            vcampus:'',
        errors:[],
        email:'',
        first_name:'',
        last_name:'',
        phone:'',
        zip:''
        }),
        props: ['programs','Title', 'BackgroundUrl','Url'],

    created() {
        if(location.search) {
            if( window.location.search.match(/(\?|&)?program\=([^&]*)/) !== null){
            var parameterValue = decodeURIComponent(window.location.search.match(/(\?|&)?program\=([^&]*)/)[2]);
            if(typeof(parameterValue) != "undefined" && parameterValue !== null) {
                var x = this.programs.find(option => option.gprogram === parameterValue);
                if(typeof(x) != "undefined" && x !== null) {
                    this.vprograms = x.value;
                    // this.$emit('ontitle',x.title);
                    // this.$emit('onbackground',x.background);
                    // this.$emit('onurl',x.location );
                    // this.$emit('onbackgroundposition', x.position);
                }
            }
        }
        }
    },
    computed:{
   isFormValid () {
      return Object.keys(this.fields).every(field => this.fields[field].valid);
    },

        link: function () {

        },

    },
     methods: {
        changeLink:function(event){
        var getLink = this.programs.find(option => option.value === event.target.value);
        this.$emit('ontitle',getLink.title);
        this.$emit('onbackground',getLink.background);
        this.$emit('onurl', getLink.location );
        this.$emit('onbackgroundposition', getLink.position);
        },
         async onSubmit(){

      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
            return;
      }



        var site_keyword = 'x';
        var site_network  = 'x';
        var site_device  = 'x';
        var site_devicemodel  = 'x';
        var site_loc_physical_ms  = 'x';
        var site_loc_interest_ms  = 'x';
        var site_placement  = 'info.westerntech.edu';
        var site_campaignid  = 'x';
        var site_adgroupid  = 'x';
        var site_matchtype  = 'x';



     if(location.search) {
        if (window.location.search.match(/(\?|&)?xxSiteId\=([^&]*)/) !== null){

     var parameterValue = decodeURIComponent(window.location.search.match(/(\?|&)?xxSiteId\=([^&]*)/)[2]);
            if(typeof(parameterValue) != "undefined" && parameterValue !== null) {
                var xxSiteId = parameterValue;
            }
        }
     }



if(typeof(xxSiteId) != "undefined" && xxSiteId !== null) {

 if (window.location.search.match(/(\?|&)?keyword\=([^&]*)/) !== null){
        var parameterValue = decodeURIComponent(window.location.search.match(/(\?|&)?keyword\=([^&]*)/)[2]);
            if(typeof(parameterValue) != "undefined" && parameterValue !== null) {
                var site_keyword = parameterValue;
            }
}

 if (window.location.search.match(/(\?|&)?network\=([^&]*)/) !== null){
        var parameterValue = decodeURIComponent(window.location.search.match(/(\?|&)?network\=([^&]*)/)[2]);
            if(typeof(parameterValue) != "undefined" && parameterValue !== null) {
                var site_network = parameterValue;
            }
}
 if (window.location.search.match(/(\?|&)?device\=([^&]*)/) !== null){
        var parameterValue = decodeURIComponent(window.location.search.match(/(\?|&)?device\=([^&]*)/)[2]);
            if(typeof(parameterValue) != "undefined" && parameterValue !== null) {
                var  site_device  = parameterValue;
           }
}
 if (window.location.search.match(/(\?|&)?devicemodel\=([^&]*)/) !== null){
        var parameterValue = decodeURIComponent(window.location.search.match(/(\?|&)?devicemodel\=([^&]*)/)[2]);
            if(typeof(parameterValue) != "undefined" && parameterValue !== null) {
                var site_devicemodel = parameterValue;
            }
}
 if (window.location.search.match(/(\?|&)?loc_physical_ms\=([^&]*)/) !== null){
        var parameterValue = decodeURIComponent(window.location.search.match(/(\?|&)?loc_physical_ms\=([^&]*)/)[2]);
            if(typeof(parameterValue) != "undefined" && parameterValue !== null) {
                var site_loc_physical_ms = parameterValue;
            }
}
 if (window.location.search.match(/(\?|&)?site_loc_interest_ms\=([^&]*)/) !== null){
        var parameterValue = decodeURIComponent(window.location.search.match(/(\?|&)?site_loc_interest_ms\=([^&]*)/)[2]);
            if(typeof(parameterValue) != "undefined" && parameterValue !== null) {
                var site_loc_interest_ms = parameterValue;
            }
}
 if (window.location.search.match(/(\?|&)?placement\=([^&]*)/) !== null){
        var parameterValue = decodeURIComponent(window.location.search.match(/(\?|&)?placement\=([^&]*)/)[2]);
            if(typeof(parameterValue) != "undefined" && parameterValue !== null) {
                var site_placement = parameterValue;
            }
}
 if (window.location.search.match(/(\?|&)?campaignid\=([^&]*)/) !== null){
        var parameterValue = decodeURIComponent(window.location.search.match(/(\?|&)?campaignid\=([^&]*)/)[2]);
            if(typeof(parameterValue) != "undefined" && parameterValue !== null) {
                var site_campaignid = parameterValue;
            }
}
 if (window.location.search.match(/(\?|&)?adgroupid\=([^&]*)/) !== null){
        var parameterValue = decodeURIComponent(window.location.search.match(/(\?|&)?adgroupid\=([^&]*)/)[2]);
            if(typeof(parameterValue) != "undefined" && parameterValue !== null) {
                var site_adgroupid  = parameterValue;
            }
}
 if (window.location.search.match(/(\?|&)?matchtype\=([^&]*)/) !== null){
        var parameterValue = decodeURIComponent(window.location.search.match(/(\?|&)?matchtype\=([^&]*)/)[2]);
            if(typeof(parameterValue) != "undefined" && parameterValue !== null) {
                var site_matchtype  = parameterValue;
            }
}





}else{
    xxSiteId = '05ku98bbd';

}



var getData = this.programs.find(option => option.value === this.vprograms);

 const vm = this;
 this.axios({

    method: 'POST',
    url: '/sender.php',

    headers: {
            'Accept': 'text/xml',
            'Accept-Language': 'en_US',
            'Content-Type':'application/x-www-form-urlencoded'
    },
    data: {

                first_name: vm.first_name,
                last_name: vm.last_name,
                phone_1: vm.phone,
                email: vm.email,
                address_1:'',
                address_2:'',
                city:'',
                state:'',
                postal_code: vm.zip,
                'education.program': vm.vprograms,
                'education.campus': '',
                phone_2: '',
                education_level:'',
                adgroupid:site_adgroupid,
                campaign_id:site_campaignid,
                keywords:site_keyword,
                matchtype:site_matchtype,
                network:site_network,
                device_category:site_device,
                devicemodel:site_devicemodel,
                loc_physical_ms:site_loc_physical_ms,
                loc_interest_ms:site_loc_interest_ms,
                placement:site_placement,
                comments:''

    },
})
.then(function(response) {
if(response.data.outcome == 'success' ){
       window.location.href = "/thanks";
}
})
.catch(function(response){

});

       //    console.log("OK!");
        }

    }
}
</script>
<style scoped lang="scss">

.invalid{
       p{
        color: #FFF;
        margin-top: 1px;
        margin-bottom: 7px;
    font-weight: 700;
      }
      input,select{
        border: 1px #EB0600 solid
    }
}

#form_body button[type='submit']:disabled {
opacity:1;
background-color: #a51c14;
cursor:pointer;

}

</style>