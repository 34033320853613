<template>
  <div>

	<h2>Welcome to Western Technical College!</h2>
	<h3><i>Offering Various Degree and Certification Programs</i></h3>
	<hr>
	<p>At Western Technical College, we believe that education is an important step to finding a rewarding career in today’s fast paced world. That’s why our admissions department is trained to guide you through the career planning process.</p>
	<p>Curriculum offerings are designed to help students prepare for career opportunities in various technical and healthcare fields. Family-owned and operated for four generations, Western Technical College began as a trade school where people could learn how to take their passion and turn it into a career they loved. Since 1970, our real-world approach to education is one of the many reasons Western Technical College Graduates find success in their careers. Western Technical College will help you achieve your educational goals through a combination of hands-on experience, traditional classroom work, and industry-specific career development.</p>
	<p>Western Tech provides quality training and education in a caring, professional environment that prepares new students and working adults with the skills they need to succeed and advance in their chosen careers.</p>

	<h2>About Western Technical College!</h2>
	<hr>
	<p>At Western Technical College we believe that education is an important step to finding a rewarding career in today’s fast paced world. That’s why our admissions department is trained to guide you through the career planning process.</p>
	<p>Curriculum offerings are designed to help students prepare for career opportunities in various technical and healthcare fields.</p>

	<h2>Features</h2>
	<hr>
	<ul>
		<li>Hands-on experience</li>
		<li>Flexible schedules</li>
		<li>200,000 square feet of program specific designated classrooms and labs</li>
		<li>Real-world equipment and trainers</li>
		<li>Hybrid courses</li>
		<li>Internship programs</li>
		<li>Certifications and professional badges</li>
		<li>Military and Veteran benefits</li>
	</ul>

  </div>
</template>

<script>



export default {
  name: 'home',
}
</script>
