<template>
  <div id="app">
    <keep-alive>
    <component :is="layout">
    </component>
  </keep-alive>
  </div>
</template>

<script>
import EmptyLayout from '@/layouts/EmptyLayout'
export default {
  computed: {
    layout() {
      return 'empty-layout'
    }
  },
  components: {
    EmptyLayout
  }
}
</script>

<style lang="scss">
  @import 'assets/style.scss';
</style>
