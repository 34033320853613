<template>
  <div class="automotive_technology">

<h2>Automotive Technology</h2>
<h3><i>Associate of Occupational Studies Degree</i></h3>
<hr>
<p>Do you love working with cars? Do you love finding and fixing problems on anything with wheels? If you are passionate about working in the Auto Technology industry, then Western Tech has a program for you! The training that you receive in the Automotive Technology Program paired with the thirty-six factory Mopar Cap training modules sets you apart from the other applicants in the Auto Technology industry, whether you applying at a Fiat Chrysler dealer or not. In addition, you’ll receive training in eight areas of the ASE standards.</p>

<p>As a student in Western Tech’s Automotive Technology program you’ll receive a unique blend of classroom and hands-on training with tremendous benefits:</p>

<ul>
<li>Hybrid Program with 80% onsite and 20% online activities</li>
<li>This program is accredited by the ASE Education Foundation and part of the Mopar Cap local school program.</li>
<li>As part of your program, you will have the opportunity to complete an internship in the automotive industry to gain real-world experience.</li>
<li>Students will receive a MATCO automotive tool set for use in school that they will get to keep when they graduate.</li>
</ul>

<h2>About Western Technical College!</h2>
<hr>
<p>At Western Technical College we believe that education is an important step to finding a rewarding career in today’s fast paced world. That’s why our admissions department is trained to guide you through the career planning process.</p>

<p>Curriculum offerings are designed to help students prepare for career opportunities in various technical and healthcare fields.</p>

<h2>Features</h2>
<hr>
<h4>Dedicated classrooms for instruction</h4>
<p><strong>Shop area equipped with:</strong></p>

<ul>
<li>Running Gas engine trainers</li>
<li>Vehicle system trainers</li>
<li>Turbo-charged vehicle trainers</li>
<li>Automotive bays</li>
<li>7,000-lb lifts</li>
<li>Alignment racks</li>
<li>Wheel balancing machines</li>
<li>Tire changing machines</li>
<li>Mopar Wi-Tech Diagnostics System</li>
<li>Automotive Diagnostic Scanners</li>
</ul>

  </div>
</template>

<script>



export default {
  name: 'automotive_technology',
}
</script>
