<template>
  <div class="nursing">
<h2>Nursing</h2>
<h3><i>Bachelors Degree</i></h3>
<hr>
With registered nurse employment growth estimated to be 24% here in Texas*; Our Bachelor of Science in Nursing program will prepare YOU for the Nursing career you’ve always wanted in just under 3 years! This is not your average Nursing program. With no waitlist and no prior medical training required you can be on your way to a new career fast.

At Western Tech, you will be surrounded with the latest equipment while training in our real-world clinical lab. Our modern patient simulation mannequins offer YOU the opportunity for MORE hands-on training. This program is a hybrid program with 80% onsite and 20% online activities.

As part of your program, you will have the opportunity to do clinical rotations in the medical industry and gain real-world experience while you earn college credit. Upon graduation, you’ll be prepared to take the NCLEX exam to become a registered nurse.

*Bureau of Labor Statistics <a href="https://www.bls.gov/oes/">2017 wage data</a> and <a href="https://www.bls.gov/emp/">2016-2026 employment projections</a> . “Projected growth” represents the estimated change in total employment over the projections period (2016-2026).

<h2>About Western Technical College!</h2>
<hr>
At Western Technical College we believe that education is an important step to finding a rewarding career in today’s fast paced world. That’s why our admissions department is trained to guide you through the career planning process.

Curriculum offerings are designed to help students prepare for career opportunities in various technical and healthcare fields.

<h2>Features</h2>
<hr>

<h4>Dedicated classrooms for instruction with:</h4>
	<ul>
		<li>Exam tables</li>
		<li>Phlebotomy and hemodialysis patient chairs</li>
		<li>Centrifuges</li>
		<li>Autoclaves</li>
		<li>Microscopes</li>
		<li>EKG, Hematocrit and Fresenius 2008K hemodialysis machines</li>
		<li>Weight and pediatric scales</li>
		<li>Digital blood pressure cuffs</li>
		<li>TV/Microscope monitor</li>
		<li>Reverse Osmosis water treatment system</li>
		<li>Loop Graft Practice Arms</li>
		<li>Peritoneal Dialysis Catheter and Permanent Catheter practice models</li>
	</ul>



  </div>
</template>

<script>



export default {
  name: 'home',
}
</script>
