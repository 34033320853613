import Vue from 'vue'


import { ValidationObserver, ValidationProvider, extend, localize} from 'vee-validate';
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)


import en from 'vee-validate/dist/locale/en.json';
import { required, email } from 'vee-validate/dist/rules';


import App from './App.vue'
// No message specified.

extend('required', required);
extend('email', email);

localize('en', en);

// Install components globally
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);


Vue.config.productionTip = false

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask);



import { Validator } from 'vee-validate';
import PhoneNumber from 'awesome-phonenumber';

extend('phone', {
    message (fieldName) {
        return `${fieldName} is not a valid phone number`;
    },
    validate (value) {
        return new Promise(resolve => {
            let phone = new PhoneNumber(value, 'US' );
            resolve({ valid: phone.isValid() })
        });
    }
});

extend('zip', {
    message (fieldName) {
        return `${fieldName} is not a valid zip code`;
    },
    validate (value) {
        return new Promise(resolve => {
				var isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
            resolve({ valid: isValidZip })
        });
    }
});


new Vue({
  render: h => h(App)
}).$mount('#app')