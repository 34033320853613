<template>
  <div class="iss">

<h2>Information Systems and Security</h2>
<h3><i>Associate of Applied Science Degree</i></h3>
<hr>

<p>When you enroll in the Information Systems and Security (IT) Degree program at Western tech, you will learn to install and configure various types of computer hardware and software to support modern networked operating systems and connected devices from vendors such as Microsoft, Cisco, and various Linux distributions. You’ll learn to configure servers, program routers and switches, and learn modern security measures that protect systems and networks.</p>

<p>Western Tech is a CompTIA, Cisco and Microsoft Academy Partner. Upon graduation, you will be eligible to test for professional certifications from CompTIA, Cisco, Microsoft and EC-Council. As part of the program, you will have the opportunity to do an internship in the computer industry to gain real-world, hands-on experience while you earn college credit.</p>

<h2>About Western Technical College!</h2>
<hr>
<p>At Western Technical College we believe that education is an important step to finding a rewarding career in today’s fast paced world. That’s why our admissions department is trained to guide you through the career planning process.</p>

<p>Curriculum offerings are designed to help students prepare for career opportunities in various technical and healthcare fields.</p>

<h2>Features</h2>
<hr>
<h4>Hybrid Program with 80% onsite and 20% online activities</h4>
<p><strong>Dedicated classrooms for instruction with:</strong></p>

<ul>
	<li>External hard drives</li>
	<li>Servers</li>
	<li>Switches</li>
	<li>Virtual software</li>
	<li>Installation software</li>
	<li>Routers</li>
	<li>Hubs</li>
	<li>Network accessories</li>
	<li>Cables</li>
	<li>NICs</li>
</ul>

  </div>
</template>

<script>



export default {
  name: 'iss',
}
</script>
