<template>
  <div class="eet">


<h2>Electronics Engineering Technology</h2>
<h3><i>Associate of Applied Science Degree</i></h3>
<hr>
<p>The Electronics Engineering Technology program at Western Tech will give you the opportunity to work on a variety of electronics that keep today’s world moving. The demand is high for knowledgeable individuals who can work on automated equipment in many high-tech industries. In the Electronics Engineering Technology program at Western Tech you will be trained in fiber optics, soldering, and communication equipment. From simple electronics to programing PLCs, Western Tech will give you  the skills you need to succeed.</p>

<p>As part of your program, you will have the opportunity to do an internship in the electronics industry and gain real-world experience.</p>

<p>As a student in the Electronic Engineering Technology program you’ll receive classroom training with tremendous benefits:</p>

<ul>
<li>Hybrid Program with 80% onsite and 20% online activities</li>
<li>Earn up to 7 certifications in:
<ul>
<li>A+ and Net+ through CompTIA</li>
<li>Fiber Optic Association (FOA)</li>
<li>High Reliability Hand Soldering IPC J Standard 001</li>
<li>ISCET: Associate Level Certified Electronics Technician, Journeyman in Communications, and Journeyman in Industrial</li>
</ul>
</li>
</ul>



<h2>About Western Technical College!</h2>
<hr>
<p>At Western Technical College we believe that education is an important step to finding a rewarding career in today’s fast paced world. That’s why our admissions department is trained to guide you through the career planning process.</p>

<p>Curriculum offerings are designed to help students prepare for career opportunities in various technical and healthcare fields.</p>

<h2>Features</h2>
<hr>
<h4>Dedicated classrooms for instruction</h4>
<p><strong>Lab equipped with:</strong></p>
	<ul>
		<li>Oscilloscopes</li>
		<li>Signal Generators</li>
		<li>Spectrum Analyzers</li>
		<li>Nida Computer-aided Electronics Trainers</li>
		<li>Robotics Trainers</li>
		<li>Programmable Logic Controllers work stations</li>
		<li>Pneumatic work stations</li>
		<li>Fiber-Optic equipment</li>
		<li>Amateur Radio Station</li>
		<li>Satellite television receiver</li>
		<li>Soldering equipment</li>
		<li>Wireless Internet Access</li>
	</ul>

  </div>
</template>

<script>



export default {
  name: 'eet',
}
</script>
