<template>
  <div class="pta">


<h2>Physical Therapist Assistant</h2>
<h3><i>Associate of Applied Science Degree</i></h3>
<hr>
<p>If you want to help people in a very hands-on way, then the physical therapist assistant program might be right up your alley. In this program, you’ll learn the necessary skills, ethics, body science, and fundamentals to be employed as a licensed Physical Therapist Assistant. As the health services industry expands because of external forces such as governmental regulations and a growing and aging population, the need for qualified, competent physical therapist assistants is projected to continue to grow.</p>

<p>The goal of the Physical Therapist Assistant program is to prepare students for careers in many different healthcare settings. Such areas may include: acute care, outpatient, rehab hospital, nursing homes, assisted living centers, home health, aquatics, wound care clinic, wellness clinic, school setting, skilled nursing facilities, state centers, prison facilities, sport and exercise clinics, physicians’ office, academia at community colleges, and the industry working with wellness and safety programs.</p>

<h2>About Western Technical College!</h2>
<hr>
<p>At Western Technical College we believe that education is an important step to finding a rewarding career in today’s fast paced world. That’s why our admissions department is trained to guide you through the career planning process.</p>
<p>Curriculum offerings are designed to help students prepare for career opportunities in various technical and healthcare fields.</p>

<h2>Features</h2>
<hr>
<h4>Dedicated classrooms for instruction</h4>
<p><strong>Lab areas are equipped with:</strong></p>

<ul>
<li>Laptop computers sufficient for each student</li>
<li>Ultrasound units</li>
<li>Electrical stimulation units (variable types)</li>
<li>Traction units</li>
<li>Wheelchairs</li>
<li>Hoyer lift</li>
<li>Stationary bikes</li>
<li>Assistive devices</li>
<li>Standing frame</li>
<li>Tilt table</li>
<li>Electrical Hi/Lo tables</li>
<li>Plinths/treatment tables</li>
<li>Upper body ergometer</li>
<li>Multiple physioballs</li>
<li>Nautilus-type equipment</li>
<li>Hydrocollator</li>
<li>Freezer</li>
<li>Paraffin bath</li>
<li>Rebounder</li>
</ul>


<div id="disclaimer">
	<p>The Physical Therapist Assistant Program at Western Technical College is accredited by the Commission on Accreditation in Physical Therapy Education (CAPTE), 1111 North Fairfax Street, Alexandria, Virginia 22314; telephone: 703-706-3245; email: accreditation@apta.org; website: <a href="http://www.capteonline.org" target="_blank" rel="noopener noreferrer">www.capteonline.org</a></p>
</div>


  </div>
</template>

<script>



export default {
  name: 'pta',
}
</script>
