<template>
  <div class="diesel_technology">

<h2>Diesel Technology</h2>
<h3><i>Associate of Occupational Studies Degree</i></h3>
<hr>
<p>Today’s world runs on diesel, the Diesel Technology Program at Western Tech will help you get started in a career working on big rigs, cruise ships, offshore drilling platforms, power stations, farm equipment, railroads, or mining equipment.</p>

<p>The Diesel Technology instructors at Western Tech have years of hands-on experience and will teach you how to diagnose, adjust, repair, overhaul, and maintain a wide variety of diesel engines.</p>

<p>As a student in Western Tech’s Diesel Technology degree program you’ll receive a unique blend of classroom and hands-on training and benefits:</p>

<ul>
<li>Hybrid Program with 80% onsite and 20% online activities</li>
<li>Diesel Advanced Technology Education (DATE) program that includes certified aftermarket training for Mack trucks and Volvo trucks</li>
<li>Get under the hood on various Big Rig diesel vehicles</li>
<li>Work on engines from Detroit Diesel, Cat, and Cummins.</li>
<li>An opportunity to have an internship in the Diesel industry with local employers to gain invaluable real-world, hands-on, experience.</li>
<li>Students will receive a Matco automotive tool set for use in school that they will get to keep when they graduate.</li>
<li>The Opportunity to choose CDL or Welding training as part of your program.</li>
<li>56 Daimler Modules that can prepare you to work with Freightliner, Western Star, and Detroit Diesel.</li>
<li>ASE Education Foundation certified</li>
</ul>

<h2>About Western Technical College!</h2>
<hr>
<p>At Western Technical College we believe that education is an important step to finding a rewarding career in today’s fast paced world. That’s why our admissions department is trained to guide you through the career planning process.</p>

<p>Curriculum offerings are designed to help students prepare for career opportunities in various technical and healthcare fields.</p>

<h2>Features</h2>
<hr>
<h4>Dedicated classrooms for instruction</h4>
<strong>Shop equipped with:</strong>

<ul>
<li>Hydraulic system trainers</li>
<li>Semi-truck trainers</li>
<li>Diesel engine trainers</li>
<li>Rolling chassis trainers</li>
<li>Electronics system trainer</li>
<li>Running engine trainers</li>
<li>A/C system trainer</li>
<li>Diesel bays</li>
</ul>





  </div>
</template>

<script>



export default {
  name: 'diesel_technology',
}
</script>
