<template>
  <div class="mca">


<h2>Medical Clinical Assistant</h2>
<h3><i>Certificate Program</i></h3>
<hr>
<p>The Medical Clinical Assistant program at Western Tech will provide the training you need to work in a clinic, doctor’s office, or hospital. Medical assisting is a versatile allied health occupation which incorporates general skills such as professionalism, communication, and medical ethics.  Administrative and front office skills are also taught so that you can manage billing and bookkeeping, electronic charting, scheduling appointments and patient data collection. Lastly, clinical skills also include phlebotomy (blood drawing), EKG, Injections and hemodialysis.</p>

<p>As part of your program, you will have the opportunity to do an internship in the medical industry and gain real-world experience while you earn college credit.</p>

<p>As a student in the Medical Clinical Assistant program you’ll receive classroom training with tremendous benefits:</p>

<ul>
	<li>Hybrid Program with 80% onsite and 20% online activities</li>
	<li>We offer training in Hemodialysis</li>
	<li>Earn up to 5 certifications</li>
</ul>


<h2>About Western Technical College!</h2>
<hr>
<p>At Western Technical College we believe that education is an important step to finding a rewarding career in today’s fast paced world. That’s why our admissions department is trained to guide you through the career planning process.</p>

<p>Curriculum offerings are designed to help students prepare for career opportunities in various technical and healthcare fields.</p>


<h2>Features</h2>
<hr>

<h4>Dedicated classrooms for instruction with:</h4>
<ul>
<li>Exam tables</li>
<li>Phlebotomy and hemodialysis patient chairs</li>
<li>Centrifuges</li>
<li>Autoclaves</li>
<li>TV/Microscope monitor</li>
<li>Reverse Osmosis water treatment system</li>
<li>Microscopes</li>
<li>EKG, Hematocrit and Fresenius 2008K hemodialysis machines</li>
<li>Weight and pediatric scales</li>
<li>Digital blood pressure cuffs</li>
<li>Loop Graft Practice Arms</li>
<li>Peritoneal Dialysis Catheter and Permanent Catheter practice models</li>
</ul>


  </div>
</template>

<script>



export default {
  name: 'mca',
}
</script>
