<template>
  <div class="business_administration">

<h2>Business Administration</h2>
<h3><i>Bachelors Degree</i></h3>
<hr>
<p>The pursuit of a Bachelor’s in Business Administration degree from Western Technical College is more than taking a collection of courses; it is an experience that teaches students not only how to think but how to apply skills that they acquire.</p>

<p>Classes are offered in Principles of Management, Microsoft Office Applications, Payroll Accounting, Logistics, Human Resources, Business Finance, Lean Six Sigma, and in other disciplines.</p>

<p>The Bachelor’s in Business Administration program offers the opportunity for students to learn and cultivate a variety of skills needed by businesses.  The program will provide students with the knowledge and technical skills needed for positions in business and may also provide students with opportunities for career advancement to reach the level of success they are striving for.</p>

<p>Students may find employment as a financial analyst, business manager, general manager, operation coordinator, logistics clerk, accounting clerk, project coordinator, project manager, advertising representative, marketing manager, sales manager, or HR assistant among other business positions.</p>

<h2>About Western Technical College!</h2>
<hr>
<p>At Western Technical College we believe that education is an important step to finding a rewarding career in today’s fast paced world. That’s why our admissions department is trained to guide you through the career planning process.</p>

<p>Curriculum offerings are designed to help students prepare for career opportunities in various technical and healthcare fields.</p>

<h2>Features</h2>
<hr>
<p><strong>Certifications include:</strong></p>

<ul>
	<li>Word</li>
	<li>Excel</li>
	<li>Payroll</li>
	<li>Bookkeeping</li>
	<li>QuickBooks</li>
	<li>Lean Six Sigma- Yellow Belt</li>
	<li>Lean Six Sigma- Green Belt</li>
	<li>Certified Associate in Project Management (CAPM)</li>
</ul>

  </div>
</template>

<script>



export default {
  name: 'business_administration',
}
</script>
