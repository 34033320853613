<template>
	<div>
		<section id="header"
			:style="{backgroundImage: 'url('+require('@/assets/'+BackgroundImage+'')+')', backgroundPosition: BackgroundPosition }"


		>
<div class="container">
	<div class="container_intro">
		<a href="/">
			<img alt="Western Tech" src="@/assets/Western-Tech-50th.png" class="img-responsive">
		</a>
<transition name="slide-fade" appear  mode="out-in">
		<h1 id="hero_cta"  v-if="show">{{TitleText}}</h1>
</transition>
	</div>
<div class="container_form" >
	<div id="mobile_thing"></div>
			<Form :programs=programs :Title=Title :BackgroundUrl=BackgroundUrl :BackgroundPoistionUrl=BackgroundPositionUrl   @onurl='onUrl' @ontitle='onTitle'  @onbackground='onBackground' @onbackgroundposition='onBackgroundPosition'/>
</div>
</div>
  <div class="arrow-up" :style="{ 'border-left-width': ArrowWidthZ + 'px' }"></div>
		</section>

	</div>
</template>

<script>
import Form  from '@/components/Form.vue'

export default {
	props: ['programs', 'Title', 'BackgroundUrl','Url', 'BackgroundPositionUrl'],
  	name: 'Header',
  	data: () => ({
		TitleText : '',
		BackgroundImage:'',
		show:true,
		ArrowWidthZ:window.innerWidth - 17,
		BackgroundPosition: 'top left'
  }),
    components: {
      Form
    },
    created() {
  window.addEventListener("resize", this.ArrowWidth);



     if(location.search) {

     	if(window.location.search.match(/(\?|&)?program\=([^&]*)/) !== null ){
            var parameterValue = decodeURIComponent(window.location.search.match(/(\?|&)?program\=([^&]*)/)[2]);
            if(typeof(parameterValue) != "undefined" && parameterValue !== null) {
                var x = this.programs.find(option => option.gprogram === parameterValue);
                if(typeof(x) != "undefined" && x !== null) {
                    this.vprograms = x.value;
          	this.TitleText = x.title;
            this.BackgroundImage = x.background;
            this.BackgroundPosition = x.position;
                }
            }else{

       var x = this.programs.find(option => option.location === 'Home');
  	this.TitleText = x.title;
            this.BackgroundImage = x.background;
            this.BackgroundPosition = x.position;

            }
}else{

       var x = this.programs.find(option => option.location === 'Home');
  	this.TitleText = x.title;
            this.BackgroundImage = x.background;
            this.BackgroundPosition = x.position;

}



        }else{
 var x = this.programs.find(option => option.location === 'Home');
  	this.TitleText = x.title;
            this.BackgroundImage = x.background;
            this.BackgroundPosition = x.position;

        }


 //          var link = window.location.pathname.toString().split('/');
 //        var x = this.programs.find(option => option.location === link[1]);
 //         if(typeof(x) != "undefined" && x !== null) {
 //           	this.TitleText = x.title;
 //            this.BackgroundImage = x.background;
 //            this.BackgroundPosition = x.position;
 //          }else{

 // var x = this.programs.find(option => option.location === 'Home');
 //  	this.TitleText = x.title;
 //            this.BackgroundImage = x.background;
 //            this.BackgroundPosition = x.position;

 //          }
            return;

},
destroyed() {
  window.removeEventListener("resize", this.ArrowWidth);
},
 	mounted() {


    },
	methods: {
		ArrowWidth () {
			this.ArrowWidthZ = window.innerWidth - 17;
		},
	  onTitle (data) {
	  	this.show=false;
	    this.TitleText = data;
		setTimeout(function () { this.show=true }.bind(this), 1000)
	  },
	 onBackground (data) {

		    this.BackgroundImage = data;
	  },
	  onBackgroundPosition (data) {

		    this.BackgroundPosition = data;
	  },
	 onUrl (data) {
		     this.$emit('onurl', data);
	  },

	}

}



</script>
<style  lang="scss">
#header{
     transition: all 2s ease-out 0.5s;
  }
</style>
