<template>
  <div class="mt">


<h2>Massage Therapy</h2>
<h3><i>Certificate Program</i></h3>
<hr>
<p>We also have a student massage clinic located at our Diana Campus. To schedule an appointment, please call 915-231-4945. </p>


  </div>
</template>

<script>



export default {
  name: 'mt',
}
</script>
