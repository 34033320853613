<template>
  <div class="advanced_welding">

<h2>Advanced Welding</h2>
<h3><i>Certificate Program</i></h3>
<hr>
<p>Welding is a hands-on skill that many businesses and industries rely on today.  As part of the Advanced Welding Technology program at Western Tech, students will learn to work from blueprints and translate plans into metal reality.  Our program allows our students to develop working knowledge of welding techniques, equipment set up and operations.</p>

<p>As a student in Advanced Welding program you’ll receive a unique blend of classroom and hands-on training with tremendous benefits:</p>

<ul>
	<li>Students receive a complete tool set to use in school and keep when they graduate.</li>
	<li>Our welding program director is a Certified Welding Inspector and Certified Welding Educator.</li>
	<li>The opportunity to complete an internship in the welding industry and gain real-world experience while you earn college credit.</li>
</ul>

<h2>About Western Technical College!</h2>
<hr>
<p>At Western Technical College we believe that education is an important step to finding a rewarding career in today’s fast paced world. That’s why our admissions department is trained to guide you through the career planning process.</p>

<p>Curriculum offerings are designed to help students prepare for career opportunities in various technical and healthcare fields.</p>

<h2>Features</h2>
<hr>

<h4>Dedicated classrooms for instruction equipped with:</h4>
<ul>
	<li>Various Blueprints/ Instructional Charts</li>
	<li>Sample Weld Displays</li>
</ul>

<h4>Welding shop / outside yard equipped with:</h4>
<ul>
	<li>Welding booths</li>
	<li>Welding machines: SMAW (Arc), GMAW (MIG), GTAW (TIG), FCAW (Flux Cored)</li>
	<li>Oxy-Acetylene Track Torches</li>
	<li>Manual Torches</li>
	<li>Plasma Cutters</li>
</ul>



  </div>
</template>

<script>



export default {
  name: 'advanced_welding',
}
</script>
