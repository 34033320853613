<template>
  <div class="medical_billing">

<h2>Medical Billing and Coding</h2>
<h3><i>Certificate Program</i></h3>
<hr>

<p>If you enjoy working in the medical field and like a challenge, the Medical Billing & Coding program at Western Tech just might be for you. Medical billers and coders play a vital role in the connection between health care providers, patients and insurance companies. Western Tech’s Medical Billing & Coding hybrid classroom and online learning program is unique in that it combines training in three critical areas: Medical Terminology with Anatomy & Physiology, medical records coding & billing, and health claims processing. Once you earn your certificate in Medical Billing & Coding, you’ll be prepared to work for a private healthcare practice, clinic, hospital, government agency, nursing facility, insurance company, or consulting firm.</p>

<p>Upon successful completion of the Medical Billing & Coding program, students may sit for exams to receive industry-standard certifications. As part of the Medical Billing & Coding program, you will have the opportunity to complete an internship in the healthcare industry to gain real-world experience while earning college credit.</p>

<h2>About Western Technical College!</h2>
<hr>
<p>At Western Technical College we believe that education is an important step to finding a rewarding career in today’s fast paced world. That’s why our admissions department is trained to guide you through the career planning process.</p>

<p>Curriculum offerings are designed to help students prepare for career opportunities in various technical and healthcare fields.</p>

<h2>Features</h2>
<hr>
<h4>Hybrid Program with 50% onsite and 50% online activities</h4>
<p><strong>Dedicated classrooms for instruction with:</strong></p>

<ul>
	<li>Laptop computers</li>
	<li>Microsoft Office</li>
	<li>SIM Chart with practice management software</li>
	<li>Health Claims Examining and Processing using CMS-1500 & UB-04</li>
	<li>Internship Program</li>
</ul>


  </div>
</template>

<script>



export default {
  name: 'medical_billing',
}
</script>
