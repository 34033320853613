<template>
	<div>

		<div class="icon-statement">
			<img class="img-responsive" src="@/assets/grad_cap.png">
			<h4>22,000+</h4>
			<h5>GRADUATES AND GROWING</h5>
		</div>

		<div class="icon-statement">
			<img class="img-responsive" src="@/assets/calendar.png">
			<h4>3</h4>
			<h5>FLEXIBLE CLASS SCHEDULE</h5>
		</div>

		<div class="icon-statement">
			<img class="img-responsive" src="@/assets/clock.png">
			<h4>45+</h4>
			<h5>YEARS OF REAL WORLD EDUCATION</h5>
		</div>

		<div class="icon-statement">
			<img class="img-responsive" src="@/assets/pillars.png">
			<h4>2</h4>
			<h5>CAMPUS LOCATIONS</h5>
		</div>

	</div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
