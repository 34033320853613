<template>
  <div class="hvac">


<h2>Refrigeration & HVAC Technology</h2>
<h3><i>Associate of Occupational Studies Degree</i></h3>
<hr>
<p>Are looking to work in a skilled trade that offers a hands-on environment? Refrigeration and HVAC Technology Training at Western Tech may be for you. Refrigeration and Heating/Ventilation/Air Conditioning (HVAC) technicians diagnose, repair, and perform preventative maintenance on a variety of highly sophisticated equipment. Their work ensures not only the comfort and safety of homeowners, but also the productivity of employees in businesses of all kinds. As part of the Refrigeration and HVAC Technology program, you will have the opportunity to complete an internship in the HVAC industry to gain real-world experience while you earn college credit.</p>

<p>As a student in Western Tech’s Refrigeration and HVAC program you’ll receive a unique blend of classroom and hands-on training with tremendous benefits:</p>

<ul>
	<li>Hybrid Program with 80% onsite and 20% online activities</li>
	<li>Students receive a complete tool set to use in school and keep when they graduate.</li>
	<li>Students are trained on EPA requirements and given the opportunity to test for this certification.</li>
	<li>Our HVAC instructors have extensive industry experience.</li>
</ul>

<h2>About Western Technical College!</h2>
<hr>
<p>At Western Technical College we believe that education is an important step to finding a rewarding career in today’s fast paced world. That’s why our admissions department is trained to guide you through the career planning process.</p>

<p>Curriculum offerings are designed to help students prepare for career opportunities in various technical and healthcare fields.</p>

<h2>Features</h2>
<hr>
<h4>Dedicated classrooms for instruction</h4>

<p><strong>Shop equipped with:</strong></p>

<ul>
	<li>400 square-foot Training House</li>
	<li>Sheet-metal fabrication room</li>
	<li>Heat-pump trainers</li>
	<li>Furnace air handler trainers</li>
	<li>Package unit trainers</li>
	<li>Commercial display trainers</li>
	<li>Low-temp trainers</li>
	<li>Medium-temp trainers</li>
	<li>Ice machine trainers</li>
	<li>Electrical motor control station</li>
	<li>Brazing/soldering stations</li>
	<li>Compu-Aire system 2100 Computer Room System</li>
	<li>Commercial walk-in Cooler System</li>
</ul>



  </div>
</template>

<script>



export default {
  name: 'hvac',
}
</script>
