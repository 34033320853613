<template>
  <div class="empty-layout">
    <Header :programs=programs :Title=Title :BackgroundUrl=BackgroundUrl :BackgroundPositionUrl=BackgroundPositionUrl  @onurl='onUrl' />
    <section id="copy">
      <div class="container">
        <div class="page">
          <transition name="slide-fade" mode="out-in">
          <keep-alive>
           <component :is="Page"></component>
         </keep-alive>
          </transition>
        </div>
        <div class="sidebar">
          <Sidebar/>
        </div>
      </div>
    </section>
    <Footer msg="EmptyLayout.vue"/>
  </div>
</template>

<script>
  import Header  from '@/components/Header.vue'
  import Sidebar from '@/components/Sidebar.vue'
  import Footer  from '@/components/Footer.vue'

  import Home from '@/views/Home.vue'
  import Nursing from '@/views/Nursing.vue'
  import Automotive_Technology from '@/views/Automotive_Technology.vue'
  import Diesel_Technology from '@/views/Diesel_Technology.vue'
  import Advanced_Welding from '@/views/Advanced_Welding.vue'
  import HVAC from '@/views/HVAC.vue'
  import EET from '@/views/EET.vue'
  import ISS from '@/views/ISS.vue';
  import Medical_Billing from '@/views/Medical_Billing.vue'
  import MCA from '@/views/MCA.vue'
  import PTA from '@/views/PTA.vue'
  import MT from '@/views/MT.vue'
  import Business_Administration from '@/views/Business_Administration.vue'
  import CDT from '@/views/CDT.vue'



  export default {

  data: () => ({
        Title:'',
        BackgroundUrl: '',
        BackgroundPositionUrl:'',
        Url:'Home',
        programs: [
             {campaign_id:'', campaign_name:'', disabled:true,  gprogram:'default', text:'Select Program', value: '', location: 'Home', title: 'Do you want a real-world approach to education?', background: 'General.jpg', position:'top left' },
            {campaign_id:'05ku9a5f9', campaign_name:'Diana Campus', disabled:false, gprogram:'nursing', text:'Bachelors in Nursing', value: 'Bachelor in Nursing', location: 'Nursing', title:'Not your average Nursing program', background: 'Nursing.jpg', position:'top left'},
            {campaign_id:'05ku9a5f8', campaign_name:'Plaza Campus Main', disabled:false, gprogram:'automotive-technology', text:'Automotive Technology', value: 'Automotive Technology', location: 'Automotive_Technology', title:'Do you love working with your hands, tools, and on cars?', background: 'AutoTech.jpg', position:'top left'},
            {campaign_id:'05ku9a5f8', campaign_name:'Plaza Campus Main', disabled:false, gprogram:'diesel-mechanics', text:'Diesel Technology', value: 'Diesel Technology', location: 'Diesel_Technology', title:'Do heavy haulers fascinate you?', background: 'Diesel.jpg', position:'top left'},
            {campaign_id:'05ku9a5f8', campaign_name:'Plaza Campus Main', disabled:false, gprogram:'advanced-welding', text:'Advanced Welding', value: 'Advanced Welding', location: 'Advanced_Welding', title:'Do you have a passion to work with metal?', background: 'Welding.jpg', position:'top left'},
            {campaign_id:'05ku9a5f8', campaign_name:'Plaza Campus Main', disabled:false, gprogram:'refrigeration-hvac-technology', text:'Refrigeration & HVAC Technology', value: 'Refrigeration & HVAC Technology', location: 'HVAC', title:'Is energy efficiency your thing?', background: 'HVAC.jpg', position:'top left'},
            {campaign_id:'05ku9a5f9', campaign_name:'Diana Campus', disabled:false, gprogram:'electronic-engineering-technology', text:'Electronic Engineering Technology', value: 'Electronics Engineering Technology', location: 'EET', title:'Are telecommunications and automation your thing?', background: 'EET.jpg', position:'top right'},
            {campaign_id:'05ku9a5f9', campaign_name:'Diana Campus', disabled:false, gprogram:'information-systems-security', text:'Information Systems and Security', value: 'Information Systems and Security', location: 'ISS', title:'Do you like to help keep businesses running?', background: 'ISS.jpg', position:'top left'},
            {campaign_id:'05ku9a5f9', campaign_name:'Diana Campus', disabled:false, gprogram:'medical-billing-coding', text:'Medical Billing and Coding', value: 'Medical Billing and Coding', location: 'Medical_Billing', title:'Healthcare with diversity, specialized knowledge and flexibility', background: 'MedicalBilling.jpg', position:'top right'},
            {campaign_id:'05ku9a5f9', campaign_name:'Diana Campus', disabled:false, gprogram:'medical-clinical-assistant', text:'Medical Clinical Assistant', value: 'Medical/Clinical Assistant', location: 'MCA', title:'Do you have an interest in health and medicine?', background: 'MCA.jpg', position:'top left'},
            {campaign_id:'05ku9a5f9', campaign_name:'Diana Campus', disabled:false, gprogram:'physical-therapist-assistant', text:'Physical Therapist Assistant', value: 'Physical Therapist Assistant', location: 'PTA', title:'Do you have a passion helping others with medical or physical conditions?', background: 'PTA.jpg', position:'top left'},
            {campaign_id:'05ku9a5f9', campaign_name:'Diana Campus', disabled:false, gprogram:'massage-therapy', text:'Massage Therapy', value: 'Massage Therapy', location: 'MT', title:'We also have a student massage clinic located at our Diana Campus', background: 'MT.jpg', position:'bottom left'},
            {campaign_id:'05ku9a5f8', campaign_name:'Plaza Campus Main', disabled:false, gprogram:'bachelors-business-administration', text:'Bachelors in Business Administration', value: 'Bachelor in Business Administration', location: 'Business_Administration', title:'Start fulfilling your professional goals', background: 'BusinessAdmin.jpg', position:'top left'},
            {campaign_id:'05ku9a5f8', campaign_name:'Plaza Campus Main', disabled:false, gprogram:'commercial-driver-training', text:'Commercial Driver Training', value: 'Commercial Driver Training', location: 'CDT', title:'Want to learn to navigate the open road?', background: 'CDT.jpg', position:'top left'},

    ]

  }),
    computed: {
          Page: function() {

            console.log(this.Url);
            return this.Url;
          }
    },
  methods: {

       onUrl(data) {
        this.Url = data;
    },
},

    components: {
      Header,Sidebar,Footer,Home,Nursing,Automotive_Technology,Diesel_Technology,Advanced_Welding,HVAC,EET,ISS,Medical_Billing,MCA,PTA,MT,Business_Administration,CDT
    }
  }
</script>
<style  lang="scss">
  .slide-fade-enter-active {
  transition: all .1s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>